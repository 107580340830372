import React, {
    createContext, useContext, useEffect, useReducer,
} from 'react';
import Button from '../primitives/button';
import RowSet from '../primitives/grid/row-set';
import DebugPill from './debug-pill';

interface DebugContext {
    showBlockNames: boolean
    showTemplateNames: boolean
    showCurrentPublishState: boolean
    showGridLines: boolean
    showCenter: boolean
}

const DebugContext = createContext<DebugContext>({} as never);

interface DebugProvider {
    children: React.ReactNode
    id: string
}

const DEBUG_LOCAL_KEY = 'uptick-debug';

const DEBUG_INITIAL_STATE = {
    showBlockNames: false,
    showTemplateNames: false,
    showCurrentPublishState: false,
    showGridLines: false,
    showCenter: false,
};

type DebugShowReducer = (state: DebugContext, action: Partial<DebugContext>) => DebugContext;

const debugShowReducer: DebugShowReducer = (state, action) => {
    const update = Object.fromEntries(Object.entries({
        ...state,
        ...action,
        // Debug context might have old options we should remove
    }).filter(([key]) => (DEBUG_INITIAL_STATE as { [key: string]: boolean })[key] !== undefined));

    try {
        localStorage.setItem(DEBUG_LOCAL_KEY, JSON.stringify(update));
        // eslint-disable-next-line no-empty
    } catch (e) {
        // ignore non-working localstorage
    }
    // This could probably be typed better
    return update as unknown as DebugContext;
};

const DebugProvider = ({ children, id }: DebugProvider): JSX.Element => {
    const shouldRenderWidget = (process.env.NODE_ENV === 'development' || process.env.GATSBY_DATO_ENV === 'drafts');

    const [visible, setVisible] = React.useState(false);
    const [show, setShow] = useReducer<DebugShowReducer, Partial<DebugContext>>(
        debugShowReducer,
        {},
        () => DEBUG_INITIAL_STATE,
    );

    const update = (key: keyof DebugContext): void => {
        setShow({ [key]: !show[key] });
    };

    useEffect(() => {
        /*
        * Update from localStorage once window is defined
        * */
        let localDebug;
        try {
            if (shouldRenderWidget) {
                localDebug = localStorage.getItem(DEBUG_LOCAL_KEY);
                if (localDebug) {
                    const parsed = JSON.parse(localDebug);
                    if (parsed && typeof parsed === 'object' && Object.keys(parsed).length > 0) {
                        setShow(parsed);
                    }
                }
            }
            // eslint-disable-next-line no-empty
        } catch (e) {
            // ignore non-working localstorage
        }
    }, [shouldRenderWidget]);

    return (
        <DebugContext.Provider value={show}>
            {shouldRenderWidget && (
                <>
                    {visible ? (
                        <div css={{
                            position: 'fixed',
                            backgroundColor: 'white',
                            width: 'min(200px, 20%)',
                            minHeight: '200px',
                            boxShadow: '0 0 8px #0004',
                            borderRadius: '8px',
                            bottom: '40px',
                            right: '40px',
                            padding: '10px',
                            zIndex: 10000,
                        }}
                        >
                            <div css={{
                                paddingBottom: '10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                fontSize: '10px',
                            }}
                            >
                                <div>Layout Debug</div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setVisible(false);
                                    }}
                                    css={{
                                        backgroundColor: '#eee',
                                        padding: '3px 6px',
                                        borderRadius: '3px',
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                            <RowSet breakpoints={{ dt: { between: 2 } }}>
                                {Object.entries(show).map(([key, val]) => (
                                    <div>
                                        <Button
                                            css={{
                                                backgroundColor: val ? 'green' : 'black',
                                                color: '#fff',
                                                padding: '4px 10px',
                                                borderRadius: '3px',
                                                transition: '0.2s',
                                                fontSize: '12px',
                                            }}
                                            onClick={() => {
                                                update(key as keyof DebugContext);
                                            }}
                                        >
                                            {key}
                                            :
                                            {(val as boolean).toString()}
                                        </Button>
                                    </div>
                                ))}
                            </RowSet>
                        </div>
                    ) : (
                        <div css={{
                            position: 'fixed',
                            bottom: '40px',
                            right: '40px',
                            padding: '4px',
                            fontSize: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            transition: '0.3s',
                            zIndex: 10000,
                        }}
                        >
                            <button
                                type="button"
                                onClick={() => {
                                    setVisible(true);
                                }}
                            >
                                <DebugPill
                                    css={{
                                        boxShadow: '0 0 8px #0004',
                                    }}
                                >
                                    Debug
                                </DebugPill>
                            </button>
                            {id && (
                                <a
                                    href={`/api/get-editing-url?id=${id}`}
                                >
                                    <DebugPill
                                        css={{
                                            boxShadow: '0 0 8px #0004',
                                            backgroundColor: 'red',
                                        }}
                                    >
                                        Edit Page
                                    </DebugPill>
                                </a>
                            )}
                        </div>

                    )}
                </>
            )}
            {children}
        </DebugContext.Provider>
    );
};

export default DebugProvider;

export const useDebug = (): DebugContext => {
    const ctx = useContext(DebugContext);
    if (!ctx) {
        throw new Error('useDebug is missing parent DebugProvider.');
    }
    return ctx;
};
